import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import LoadingIcon from '../../components/LoadingIcon';
import PageCollection from '../../components/PageCollection';
import PageCollectionItem from '../../components/PageCollectionItem';
import { Text } from 'legacy-ui';
import Container from '../../sections/common/Container';
import { PagedAPIResult } from '../../types/api/commonTypes';
import { PropertyGroupAPIResult } from '../../types/api/propertyGroupTypes';
import { GeneralCMSResult, PropertiesCMSResult } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { getImageFromApiURl, loadFromApi } from '../../utils/http';
import { getPropertyGroupLink } from '../../utils/links';

const PAGE_SIZE = 9;

function getEndpoint(postalCode: string | undefined, municipality: string | undefined) {
  if (postalCode) {
    return 'property-group/find-by-postalcodes-and-tags';
  }
  if (municipality) {
    return 'property-group/find-by-municipality-and-tags';
  }
  return 'property-group/find-by-tags';
}

const StyledPropertyCollection = styled.section`
  padding-top: 80px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding-top: 112px;
  }
  h2 {
    text-align: center;
    margin-bottom: 32px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin-bottom: 48px;
    }
  }
`;

const PropertyCollectionLoading = styled.div`
  padding: 48px 0;
`;

const PropertyCollectionEmpty = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.gray60};
  padding: 24px 0;
  margin-bottom: 64px;
`;

interface PropertyCollectionProps {
  propertiesData: PropertiesCMSResult;
  generalTexts: GeneralCMSResult;
}

function PropertyCollection({ propertiesData, generalTexts }: PropertyCollectionProps) {
  const { locale } = useRouter();

  const [propertyGroups, setPropertyGroups] = useState<PropertyGroupAPIResult[]>([]);
  const [page, setPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { Municipality, PostalCode, Tags } = propertiesData;
      if (!Municipality && !PostalCode && !Tags?.length) {
        setPropertyGroups([]);
        setTotalProperties(0);
        setLoading(false);
        return;
      }
      const endpoint = getEndpoint(PostalCode, Municipality);
      setLoading(true);
      const { pageEntries, totalEntries }: PagedAPIResult<PropertyGroupAPIResult> = await loadFromApi(endpoint, {
        municipality: Municipality,
        postalcodes: [PostalCode],
        tags: Tags?.map((tag) => tag.Entry),
        page,
        pageSize: PAGE_SIZE,
      });
      setPropertyGroups(pageEntries);
      setTotalProperties(totalEntries);
      setLoading(false);
    })();
  }, [page, propertiesData]);

  return (
    <StyledPropertyCollection>
      <Container>
        <Heading size="s" tag="h2">
          {propertiesData.Headline}
        </Heading>
        {loading ? (
          <PropertyCollectionLoading>
            <LoadingIcon />
          </PropertyCollectionLoading>
        ) : !totalProperties ? (
          <PropertyCollectionEmpty>
            <Text>{generalTexts.Misc.NoPropertiesFound}</Text>
          </PropertyCollectionEmpty>
        ) : (
          <PageCollection
            page={page}
            setPage={setPage}
            totalResults={totalProperties}
            isLoading={loading}
            pageSize={PAGE_SIZE}
            generalTexts={generalTexts}
          >
            {propertyGroups?.map((propertyGroup, index) => {
              const firstPropertyGroupImage = propertyGroup.images?.[0];
              const propertyGroupImage = firstPropertyGroupImage
                ? getImageFromApiURl(firstPropertyGroupImage.name, 480)
                : 'https://app.propstep.com/api/image/find-public/pg-60192d6f7f2baf290ecbc4cb-60e83cbec6198c5ea05ba130.jpeg?resize=true&width=480'; // Dummy placeholder
              return (
                <PageCollectionItem
                  key={index}
                  href={getPropertyGroupLink(propertyGroup, locale as LanguageKey)}
                  imageSrc={propertyGroupImage}
                  imageAlt={firstPropertyGroupImage?.description || propertyGroup.name}
                  heading={propertyGroup.name}
                  isUpcoming={propertyGroup.upcomingProject}
                  generalTexts={generalTexts}
                />
              );
            })}
          </PageCollection>
        )}
      </Container>
    </StyledPropertyCollection>
  );
}

export default PropertyCollection;
