import { format, parseISO } from 'date-fns';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import PageCollection from '../../components/PageCollection';
import PageCollectionItem from '../../components/PageCollectionItem';
import Container from '../../sections/common/Container';
import { ArticleCMSResult, ArticleTagCMSResult } from '../../types/cms/articleTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { getArticlesLink } from '../../utils/links';

const RelatedArticlesHeader = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.gray30};
  padding-top: 96px;
  h2 {
    margin-bottom: 48px;
    text-align: center;
  }
`;

interface RelatedArticlesProps {
  heading: string;
  currentArticle?: ArticleCMSResult;
  tag?: ArticleTagCMSResult;
  generalTexts: GeneralCMSResult;
}

const RelatedArticles = ({ heading, currentArticle, tag, generalTexts }: RelatedArticlesProps) => {
  const { locale } = useRouter();
  const articles =
    currentArticle?.Category.data.attributes.Articles.data.filter((article) => article.attributes.Slug !== currentArticle.Slug) || tag?.Articles.data;

  if (!articles?.length) {
    return null;
  }
  const sortedArticles = articles
    .filter((article) => {
      // Validation: Filter out any articles that look in any way broken.
      // We only want related articles to be fully-fletched out.
      const warnReject = (missingField: string) =>
        console.warn(`Article appears broken and was excluded from RelatedArticles list: ${missingField} was undefined`, { article });

      if (!article.id) {
        warnReject('id');
        return false;
      }

      const { attributes } = article;
      if (!attributes) {
        warnReject('attributes');
        return false;
      }

      if (!attributes.PageTitle || !attributes.Slug || !attributes.Category || !attributes.updatedAt) {
        warnReject('expected attributes field (PageTitle, Slug, Category or updatedAt)');
        return false;
      }

      const { Image, Category } = attributes;
      if (!Image?.data?.attributes?.formats?.medium?.url) {
        warnReject('Image medium format url');
        return false;
      }
      if (!Category?.data?.attributes?.Name) {
        warnReject('Category name');
        return false;
      }

      return true;
    })
    .sort((a, b) => a.attributes.updatedAt.localeCompare(b.attributes.updatedAt));

  return (
    <Container>
      <RelatedArticlesHeader>
        <Heading size="s" tag="h2">
          {heading}
        </Heading>
      </RelatedArticlesHeader>
      <PageCollection generalTexts={generalTexts} totalResults={sortedArticles.length}>
        {sortedArticles.map(({ id, attributes: { PageTitle, Image, Slug, Category, updatedAt } }) => (
          <PageCollectionItem
            key={id}
            href={`${getArticlesLink(locale as LanguageKey)}/${Slug}`}
            imageSrc={Image.data.attributes.formats.medium.url}
            imageAlt={PageTitle}
            heading={PageTitle}
            text={format(parseISO(updatedAt), 'dd MMM Y')}
            textPrepend={Category.data.attributes.Name}
          />
        ))}
      </PageCollection>
    </Container>
  );
};

export default RelatedArticles;
