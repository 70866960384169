import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Text } from 'legacy-ui';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

const StyledBreadcrumbs = styled.ul<BreadcrumbsProps>`
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin-bottom: 80px;
  }
  ${(props) => {
    if (props.hideBreadcrumbsOnMobile) {
      return `
        @media only screen and (max-width: ${props.theme.breakpoint.lg}) {
          display: none;
        }
    `;
    }
  }}
  li {
    margin-right: 12px;
    &:first-of-type {
      > p {
        opacity: 0.6;
      }
    }
    a {
      display: block;
      &[href] {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
    span {
      opacity: 0.6;
    }
    svg {
      opacity: 0.6;
    }
  }
`;

export interface Breadcrumb {
  title: string;
  link?: string;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  hideBreadcrumbsOnMobile?: boolean;
}

function Breadcrumbs({ breadcrumbs, hideBreadcrumbsOnMobile }: BreadcrumbsProps) {
  const [last, ...rest] = [...breadcrumbs].reverse();
  const links = [...rest].reverse();

  const { isWhiteLabel } = useWhiteLabel();

  return (
    <StyledBreadcrumbs breadcrumbs={breadcrumbs} hideBreadcrumbsOnMobile={hideBreadcrumbsOnMobile}>
      {links.map(({ title, link }, index) => (
        <Fragment key={index}>
          <li>
            {link ? (
              isWhiteLabel && index === 0 ? (
                <span>
                  <Text size="s" weight="m">
                    {title}
                  </Text>
                </span>
              ) : (
                <Link href={link!} passHref>
                  <a>
                    <Text size="s" weight="m">
                      {title}
                    </Text>
                  </a>
                </Link>
              )
            ) : (
              <Text size="s" weight="m">
                {title}
              </Text>
            )}
          </li>
          <li>
            <Text size="s">
              <FontAwesomeIcon icon={faAngleRight} />
            </Text>
          </li>
        </Fragment>
      ))}
      <li>
        <Text size="s" weight="m">
          {last.title}
        </Text>
      </li>
    </StyledBreadcrumbs>
  );
}

export default Breadcrumbs;
