import styled, { keyframes } from 'styled-components';
import LoadingIconSvg from '../assets/loading-icon.svg';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIconWrapper = styled.div`
  svg {
    width: 48px;
    height: 48px;
    perspective: 1000px;
    animation: ${spin} 2s infinite linear;
    margin: 0 auto;
  }
`;

function LoadingIcon() {
  const { theme } = useWhiteLabel();

  return (
    <LoadingIconWrapper>
      <LoadingIconSvg color={theme.color.secondaryBackgroundColorDisabledBorder} />
    </LoadingIconWrapper>
  );
}

export default LoadingIcon;
