import Image from 'next/image';
import styled from 'styled-components';
import Breadcrumbs, { Breadcrumb } from '../../components/Breadcrumbs';
import { Heading } from 'legacy-ui';
import { Text } from 'legacy-ui';
import Container from './Container';

interface PageHeroProps {
  white?: Boolean;
  smallerHeight?: Boolean;
  image?: string;
  breadcrumbs?: Breadcrumb[];
  preheading?: string;
  heading?: string;
  text?: string;
  companyLogo?: string;
}

const StyledPageHero = styled.section<PageHeroProps>`
  background-color: ${(props) => props.theme.color.pageHeroBackgroundColor};
  background-position: center center;
  background-size: cover;
  padding: 112px 0 80px 0;
  text-align: center;
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 164px 0 112px 0;
  }
  ${(props) => {
    if (props.breadcrumbs) {
      return `
        padding: 80px 0 112px 0;
        @media only screen and (min-width: ${props.theme.breakpoint.md}) {
          padding: 112px 0;
        }
      `;
    }
  }}
  ${(props) => {
    if (props.white) {
      return `
      color: white;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
      }
      `;
    }
  }}
  ${(props) => {
    if (props.smallerHeight) {
      return `
        min-height: 140px;
        > div > ul {
          margin-bottom 40px;
        }
        padding: 80px 0 0;
        background-color: white;
        > div {
          padding: 0;
        }
        @media only screen and (min-width: ${props.theme.breakpoint.md}) {
          min-height: 180px;
          padding: 112px 0 0;
          > div > ul {
            margin-bottom 50px;
          }
        }
      `;
    }
  }}
  h2 {
    margin-bottom: 12px;
  }
  h1 + p {
    max-width: 800px;
    margin: 24px auto 0 auto;
  }
`;

const CompanyLogo = styled.div`
  margin-bottom: 8px;
  img {
    border-radius: 100%;
  }
`;

function PageHero({ white: white, smallerHeight, image, breadcrumbs, preheading, heading, text, companyLogo }: PageHeroProps) {
  return (
    <StyledPageHero
      breadcrumbs={breadcrumbs}
      {...(image && { style: { backgroundImage: `url(${image})` } })}
      white={white}
      smallerHeight={smallerHeight}
    >
      <Container>
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {companyLogo && (
          <CompanyLogo>
            <Image src={companyLogo} width={64} height={64} alt={heading} />
          </CompanyLogo>
        )}
        {preheading && <Heading tag="h2">{preheading}</Heading>}
        <Heading size="l" tag="h1">
          {heading}
        </Heading>
        {text && <Text>{text}</Text>}
      </Container>
    </StyledPageHero>
  );
}

export default PageHero;
